.section {
    padding: 2.5rem 0;

    &__img {
        max-width: 700px;
    }

    @include media-breakpoint-up(lg){
        padding: 4rem 0;

        &--small {
            padding: 3.5rem 0;
        }
    }

    @include media-breakpoint-up(xl){
        padding: 5rem 0;
    }

}