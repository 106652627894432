.catalog-img {
    max-width: 100%;
    height: 225px;
    object-position: center;
    object-fit: cover;
    aspect-ratio: 3 / 4;
    margin-bottom: 1.5rem;
    box-shadow: 0px 4px 13px rgba(9, 13, 87, 0.15);

    @include media-breakpoint-up(sm){
        height: 300px;
    }
    @include media-breakpoint-up(lg){
        height: 400px;
    }
}