.navbar {
    padding-top: 1.75rem;
    padding-bottom: 2rem;
    height: 100%;

    @include media-breakpoint-down(xl){
        padding-top: 1rem;
        padding-bottom: 1rem;
        align-items: flex-start;
        text-align: left;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        .navbar-brand {
            img {
                max-width: 180px;
            }
        }
        
    }

    .navbar-toggler {
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
    
    ul {
        display: inline-flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        padding-left: 0;
        margin-left: auto;
        margin-bottom: 0;
        list-style: none;

        li {
            font-size: 1em;
            font-weight: 500;
            padding: .5rem .75rem;
            text-transform: capitalize;
            
            a {
                color: var(--theme-color-black);
                transition: color .25s ease-in-out;
                
                &:hover,
                &:focus,
                &:focus-visible {
                    color: var(--theme-color-grey);
                }
            }

            &.dropdown {
                position: inherit;
                
                @include media-breakpoint-down(xl){
                    transition: background-color .3s cubic-bezier(0.165, 0.84, 0.44, 1);
                }

                .dropdown-toggle {
                    &::after {
                        float: right;
                        border: solid var(--theme-color-grey);
                        border-width: 0 2px 2px 0;
                        display: inline-block;
                        margin-top: .35rem;
                        padding: 4px;
                        transform: rotate(45deg);
                        opacity: 1;
                        transition: transform .5s cubic-bezier(0.165, 0.84, 0.44, 1);
                        
                        @include media-breakpoint-up(xl){
                            display: none;
                            padding: 3px;
                            float: none;
                        }
                    }
                }

                &.show {

                    .dropdown-toggle {
                        &::after {
                            @include media-breakpoint-down(xl){
                                transform: rotate(225deg);
                            }
                        }
                    }
                }

                .dropdown-menu {
                    position: relative;
                    overflow: hidden;
                    display: none;
                    left: 0;
                    right: 0;
                    top: 100%;
                    border-radius: 0;
                    padding: 0;
                    height: 100%;
                    border: none;
                    margin: auto;
                    color: var(--theme-color-black);
                    background-color: transparent;
                    transition: color .3s ease;
                    z-index: 9999;
                    
                    @include media-breakpoint-up(xl){
                        position: absolute;
                        height: auto;
                        min-height: 370px;
                        max-height: 420px;
                        box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.15);
                        background-color: var(--theme-color-grey-light);
                    }

                    &::before {
                        content: '';
                        display: none;
                        position: absolute;
                        background-color: #F1F2F4;
                        top: 0;
                        right: 0;
                        height: 100%;
                        width: calc((100% * 0.33) + .75rem);
                        z-index: -1;

                        @include media-breakpoint-up(xl){
                            display: block;
                        }
                        @include media-breakpoint-up(xxl){
                            width: calc((100% - 1320px)/2 + (1320px * 0.33) + 6px);
                        }
                    }

                    &.show {
                        display: block;
                        min-width: 100%;
                    }

                    &__container {
                        @extend .container-xxl;
                        height: 100%;
                        max-height: inherit;

                        @include media-breakpoint-down(xl){
                            padding-top: 0.75rem;
                        }
                        @include media-breakpoint-between(xl,xxl){
                            padding-left: 2rem;
                            padding-right: 2rem;
                        }
                    }
                    
                    &__row {
                        @extend .row;
                        height: 100%;
                        max-height: inherit;
                    }

                    &__subtitle {
                        position: relative;
                        padding-bottom: .25rem;
                        margin-bottom: 1rem;
                        z-index: 4;
                        
                        &::before {
                            content: '';
                            position: absolute;
                            left: 0;
                            bottom: -2px;
                            height: 1px;
                            background-color: var(--theme-color-black);
                            opacity: 0.5;
                            width: 70px;
                            z-index: -1;
                        }
                    }

                    &__body {
                        font-weight: 400;
                        text-transform: none;
                        color: var(--theme-color-grey);

                        a {
                            display: inline-block;
                            width: initial;
                            color: var(--theme-color-black);

                            &:hover,
                            &:focus-visible {
                                color: var(--theme-color-grey);
                            }
                            
                        }

                        ul {
                            display: block;
                            padding-left: 1rem;
                            list-style-image: url("data:image/svg+xml,%3Csvg width='7' height='13' viewBox='0 0 7 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 6.5L0.859649 13L0 12.09L5.2807 6.5L0 0.91L0.859649 0L7 6.5Z' fill='%237A8796'/%3E%3C/svg%3E%0A");
                            margin: 0 0 .5rem;
                            left: 0;

                            li {
                                text-transform: none;
                                padding: 0;
                                font-weight: 400;

                                a {
                                    font-weight: 500;
                                    color: var(--theme-color-black)
                                }
                            }
                        }
                    }

                    &__col {
                        @include make-col(12);
                        font-size: .875em;
                        padding-top: .5rem;
                        padding-bottom: .5rem;

                        @include media-breakpoint-up(xl){
                            font-size: 1em;
                            @include make-col(4);
                            padding-top: 3rem;
                            padding-bottom: 2rem;
                            padding-right: 1.5rem;
                        }

                        &--6 {
                            @include make-col(12);
                            
                            @include media-breakpoint-up(xl){
                                @include make-col(6);
                            }
                        }


                        &:nth-child(3) {
                            @include make-col(4);
                            color: var(--theme-color-black);
                            padding-left: 1.5rem;

                            @include media-breakpoint-down(xl){
                                display: none;
                            }

                            .dropdown-menu__subtitle {
                                color: var(--theme-color-black);

                                &::before {
                                    background-color: var(--theme-color-black);
                                }
                            }

                            .dropdown-menu__body {
                                font-weight: 300;
                                color: var(--theme-color-black);

                                a {
                                    color: var(--theme-color-black);

                                    &:hover,
                                    &:focus-visible {
                                        color: var(--lk-white);
                                    }
                                }
                            }
                        }
                    }

                    &__news {
                        display: flex !important;
                        font-weight: 400;
                        line-height: 1.2;
                        margin-bottom: 1.25rem;
                        color: inherit !important;
                        gap: 1rem;

                        img {
                            width: 100px;
                            height: 100px;
                            object-fit: cover;
                            object-position: center;
                            border-radius: 0.125rem;
                        }

                        &--big {
                            flex-direction: column;
                            margin-bottom: 0;
                            max-width: 400px;

                            img {
                                width: 100%;
                                height: 200px;
                            }
                        }
                    }
                }
            }

            &.navbar-logo {
                display: none;
                padding: 0 1rem 0 0;

                svg {
                    max-height: 55px;
                }
            }
        }

        @include media-breakpoint-down(xl){
            flex-direction: column;

            li {
                padding: .25rem 1rem .25rem .75rem;
                width: 100%;

                a {
                    display: block;
                    line-height: 1.2;
                    width: 100%;
                }
            }
        }
    }

    .languages-dropdown {
        position: relative;
        width: initial;
        padding: .25rem;
        background-color: transparent;

        button {
            font-weight: 500;
            background: none;
            border: none;

            &::after {
                border: none;
                width: 8px;
                height: 8px;
                border-bottom: 2px solid;
                border-right: 2px solid;
                transform: rotate(45deg);
                left: 4px;
                top: 2px
            }
        }

        ul {
            position: relative;
            top: 100%;
            display: none;
            border: none;
            min-width: 1rem;
            z-index: 9;

            li {
                padding: 0;
                text-align: center;

                a {
                    font-weight: 500;
                    background-color: transparent;
                }
            }

            &.show {
                display: block;
            }

            @include media-breakpoint-up(xl){
                position: absolute;
                box-shadow: 0px 4px 8px rgba(33, 33, 33, 0.1);
            }
        }
    }
}