.faq {
    margin-bottom: 3.5rem;

    &__item {
        border-bottom: 1px solid #efefef;
    }

    &__btn {
        @extend .accordion-button;
        padding: 1.25rem .5rem;
        
        &:not(.collapsed) {
            color: var(--theme-color-black);
            background-color: transparent;

            &::after {
                background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
            }
        }

        &:focus {
            border: none;
            box-shadow: none;
            outline: none;
        }

        @include media-breakpoint-up(md){
            font-size: 1.125rem;
        }
    }

    &__body {
        padding: 1.25rem .5rem;
    }
}