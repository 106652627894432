.titlebar {
    position: relative;
    padding: 4rem 0;
    overflow: hidden;
    background-image: url('./../img/svg/titlebar-bg.svg');
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 9;

    &--big {
        padding: 6rem 0;
    }
    
    @include media-breakpoint-up(md){
        height: 250px;
        background-position: center;
        background-size: contain;

        &--big {
            height: 350px;
            padding: 4rem 0;
        }
    }

    .container,
    .row {
        height: 100%;
    }

    h1 {
        font-weight: 800;
        letter-spacing: 1px;

        @include media-breakpoint-up(xl){
            font-size: 3.5rem;
        }
    }

    &__logo {
        max-width: 600px;
        max-height: 150px;
        
        &--width {
            max-width: 100%;
        }

        @include media-breakpoint-up(xl){
            max-height: initial;

        }
    }
}