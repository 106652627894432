.counters {
    &__container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 1rem;
        padding: 0 .75rem;
        
        @include media-breakpoint-up(sm){
            gap: 1.5rem;
            padding: 0 1.5rem;
            grid-template-columns: repeat(2, 1fr);
        }
        @include media-breakpoint-up(lg){
            grid-template-columns: repeat(6, 1fr);
        }
        @include media-breakpoint-up(xl){
            grid-template-columns: repeat(12, 1fr);
        }
    }

    &__item {
        position: relative;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        justify-content: space-between;
        color: $white;
        min-height: 250px;
        background-color: #ccc;
        z-index: 9;
        padding: 2rem;

        .__name {
            font-size: .875rem;
            line-height: 1.3;
            letter-spacing: 1px;
            font-weight: 600;
        }
        
        .__value {
            font-size: 1.75rem;
            line-height: 1.3;
            font-weight: 600;

            span {
                font-size: 1rem;
            }

            div {
                font-size: 1rem;
                letter-spacing: 1px;
            }
        }

        .__image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -2;
            transition: transform 1.2s cubic-bezier(0.175, 0.885, 0.32, 1);
            
            img {
                width: 100%;
                height: 100%;
                object-position: center;
                object-fit: cover;
            }
        }

        .__overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            background-color: var(--theme-color-primary);
            opacity: .7;
        }
        
        @include media-breakpoint-up(lg){
            grid-column: span 2;
        }

        @include media-breakpoint-up(xl){

            .__value {
                font-size: 2.5rem;
            }

            &:nth-child(1),
            &:nth-child(3),
            &:nth-child(6),
            &:nth-child(7),
            &:nth-child(9),
            &:nth-child(12) {
                grid-column: span 3;
            }
    
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(10) {
                grid-column: span 4;
            }
        }

        @include media-breakpoint-up(xxl){
            .__name {
                font-size: 1.125rem;
            }

            .__value {
                font-size: 3.5rem;

                span {
                    font-size: 2rem;
                }

                div {
                    font-size: 1.125rem;
                }
            }
        }

        &:hover {
            .__image {
                transform: scale(1.2) rotate(-3deg);
            }
        }
    }
}