.brand {
    &__thumb {
        width: 100%;
        height: 400px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
        
        @include media-breakpoint-up(md) {
            height: 500px;
        }
    }

    &__slider {
        
        .__item  {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 110px;
            padding: .5rem 1rem;

            img {
                max-width: 90%;
                max-height: 80px;
                height: auto;
                
                @include media-breakpoint-up(sm){
                    max-height: 40px;
                    max-width: 98%;
                }
            }
        }

        .slick-dots {
            display: flex;
            justify-content: center;
            list-style: none;
            gap: .75rem;
            padding: 0;
            margin: 1.5rem auto .5rem;

            .dot {
                cursor: pointer;
                width: 10px;
                height: 10px;
                background-color: var(--theme-color-grey);
                border-radius: 100%;
                transition: background-color .3s ease-in-out;
            }
            
            .slick-active {
                .dot {
                    background-color: var(--theme-color-primary);
                }
            }
        }
    }
}