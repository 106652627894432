$font-prime: "Poppins", sans-serif;

$headings-font-weight: 600;
$headings-color: var(--theme-color-black);
$headings-margin-bottom: 1.25rem;

html {
    --theme-color-black: #23292E;
    --theme-color-primary: #0A2240;
    --theme-color-blue: #2DAAE1;
    --theme-color-grey: #7A8796;
    --theme-color-grey-light: #FBFBFB;
}

// Customization Bootstrap vars from "app/libs/bootstrap/scss/_variables.scss"
// $grid-gutter-width: 30px;
// $grid-columns: 12;
// $grid-breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px);
// $container-max-widths: (sm: 540px, md: 720px, lg: 960px, xl: 1140px);

// $gutter: $grid-gutter-width;
