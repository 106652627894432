.footer {
    text-align: center;
    padding: 4.5rem 0 2.5rem;
    background-image: url('./../img/svg/footer-bg.svg');
    background-repeat: no-repeat;
    background-position: 30% bottom;

    @include media-breakpoint-up(md){
        text-align: left;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        color: var(--theme-color-black);
    }

    a {
        color: var(--theme-color-black);

        &:hover {
            color: var(--theme-color-grey);
        }
    }

    &__copyrights {
        font-size: .875rem;
    }
}