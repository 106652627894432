.cta {
    color: $white;
    padding: 4rem 0;
    background-image: url('./../img/svg/cta-bg.svg');
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    text-align: center;
    
    
    @include media-breakpoint-up(xl){
        text-align: left;
        padding: 3.5rem 0;

        &--big {
            padding: 5.5rem 0;
        }
    }

    .btn {
        @include media-breakpoint-down(sm){
            min-width: 150px;
        }
    }

    &__title {
        font-size: 2.25rem;
        line-height: 1.2;
        font-weight: 600;
        margin-bottom: 2rem;
        
        @include media-breakpoint-up(lg){
            font-size: 3rem;
            margin-bottom: 1.5rem;
        }
        @include media-breakpoint-up(xl){
            margin-bottom: 0;
        }
        @include media-breakpoint-up(xxl){
            font-size: 3.5rem;
        }
    }
}