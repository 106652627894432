.intro {
	position: relative;
	overflow: hidden;
	background-color: var(--theme-color-grey-light);
	z-index: 9;
	
	&::before {
		content: '';
		position: absolute;
		height: 600px;
		width: 630px;
		left: -25%;
		top: 10%;
		background-image: url('./../img/svg/intro-bg.svg');
		background-position: center;
		background-repeat: no-repeat;
		z-index: -1;
		
		@include media-breakpoint-up(lg){
			top: auto;
			left: -7%;
			bottom: -30%;
		}
	}

	.row {
		min-height: 400px;
		
		@include media-breakpoint-up(lg){
			min-height: 600px;
		}
	}
	
	&__img {	
		width: 100%;
		height: 400px;
		
		@include media-breakpoint-up(lg){
			position: absolute;
			top: 0;
			right: 0;
			width: 50%;
			height: 100%;
		}
		
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}

	&--reverse {
		@include media-breakpoint-up(lg){
			&::before {
				top: auto;
				right: -7%;
				left: auto;
				bottom: -30%;
			}
		}
	}

	&--reverse  &__img {
		@include media-breakpoint-up(lg){
			left: 0;
			right: auto;
		}
	}
}

.single {
	&__intro {
		overflow: hidden;
		padding-top: 3rem;

		.container {
			@extend .container-xxl;

			@include media-breakpoint-down(xxl){
				padding-left: 0;
				padding-right: 0;
				max-width: 100%;
			}
		}
	}

	&__thumb {
		height: 400px;

		&--medium {
			height: 360px;
			
			@include media-breakpoint-up(md){
				height: 550px;
			}
		}

		img {
			width: 100%;
			height: 100%;
			object-position: center;
			object-fit: cover;
		}
	}

	&__title {
		margin-bottom: 0;

		@include media-breakpoint-up(lg){
			font-size: 3.5rem;
		}
	}
}

.content {
	a {
		color: var(--theme-color-black);
		text-decoration: underline;
	}

	pre {
		font-family: inherit;
		font-size: 1rem;
		font-weight: 600;
		margin-bottom: 1rem;
	}
}

.gallery {
	&__img {
		position: relative;
		max-width: 100%;
		height: 280px;
		z-index: 9;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}

	&__desc {
		position: absolute;
		bottom: 0;
		width: 100%;
		padding: .5rem;
		background-color: rgba(12, 76, 163, 0.7);
		color: $white;
	}
}