.boxes {
    &__container {
        display: grid;
        grid-template-columns: 1fr; 
        margin: auto;
        max-width: 1800px;
        padding-left: 1rem;
        padding-right: 1rem;
        gap: 1.5rem;
        
        @include media-breakpoint-up(sm){
            grid-template-columns: 1fr 1fr; 
        }
        @include media-breakpoint-up(md){
            padding-left: 3rem;
            padding-right: 3rem;
        }

        @include media-breakpoint-up(lg){
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    .boxes-item {
        display: inline-flex;
        flex-wrap: wrap;
        flex-direction: column;
        z-index: 8;

        &__img {
            position: relative;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 280px;
            background-position: top;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: #ccc;
            z-index: 8;

            @include media-breakpoint-up(md){
                height: 350px;
            }

            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background: linear-gradient(0deg, rgba(10, 21, 120, 0.56), rgba(10, 21, 120, 0.56));
                top: 0;
                left: 0;
                opacity: 0;
                transition: opacity .45s cubic-bezier(0.445, 0.645, 0.355, 1);
                z-index: -1;
            }

            img {
                width: 100%;
                height: 100%;
                object-position: center;
                object-fit: cover;
            }
        }

        &__logo {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin: 1rem auto;
            width: 90%;
            height: 70px;
            
            @include media-breakpoint-up(md){
                width: 300px;
            }
            
            img {
                max-width: 90%;
                height: auto;
                max-height: 45px;

                @include media-breakpoint-up(xl){
                    max-width: 100%;
                }
            }
        }

        &__link {
            text-align: center;
            opacity: 0;
            transition: opacity .45s cubic-bezier(0.445, 0.645, 0.355, 1);
        }

        &:hover {
            .boxes-item__link {
                opacity: 1;
            }

            .boxes-item__img {
                &::before {
                    opacity: 1;
                }
            }

        }

        &--static {
            padding: 2rem 1.25rem 6rem;
            display: none;
            flex-direction: column;
            text-align: center;
            align-items: center;
            justify-content: center;
            background-color: transparent;

            &::before {
                display: none;
            }

            h2 {
                font-size: 2.5rem;
                margin-bottom: 1.5rem;
            }

            @include media-breakpoint-up(md){
                display: inline-flex;
            }

            @include media-breakpoint-up(xxl){
                padding: 2rem 2rem 6rem;

                h2 {
                    font-size: 3.5rem;
                }
            }
        }
    }
}

.news {
    position: relative;
    overflow: hidden;
    z-index: 9;

    &__grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 1.6rem;
        
        @include media-breakpoint-up(lg){
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 1.6rem;
            
            &--cols {
                grid-template-columns: 1fr 1fr 1fr;

                .news__col {
                    margin-bottom: 3rem;
                }
            }
        }
    }

    &__categories {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        gap: 1rem;

        @include media-breakpoint-down(sm){
            flex-wrap: nowrap;
            overflow: scroll;
            justify-content: flex-start;

            &::-webkit-scrollbar {
                display: none;
            }

            a {
                min-width: 200px;

                &:first-of-type(){
                    margin-left: 1.5rem;
                }
                &:last-of-type(){
                    margin-right: 1.5rem;
                }
            }
        }
    }

    &__col {
        display: grid;
        gap: 3rem;

        &--12 {
            grid-column: span 3;
        }

        &:first-of-type(){
            margin-bottom: 3rem;
        }
    }

    @include media-breakpoint-up(lg){
        padding: 3.5rem 0 1.5rem;
        
        &__col {
            gap: 1.6rem;

            &:first-of-type(){
                margin-bottom: 0;
            }
        }
    }

    &::before {
        content: '';
        position: absolute;
        top: 40%;
        width: 200px;
        height: 340px;
        left: calc((100% - 1320px)/2 - 155px);
        background-image: url('./../img/svg/news-bg.svg');
        background-repeat: no-repeat;
        z-index: -1;
    }
}

.quote {
    font-size: 1.75rem;
    line-height: 1.4;
    text-align: center;
    font-weight: 600;
    padding: 2rem 0;

    strong {
        font-size: 2.5rem;
    }
    
    span {
        color: var(--theme-color-black);
        font-size: 1.5rem;
        font-weight: 700;
        text-align: right;
        margin-top: 1rem;
        display: block;
    }

    @include media-breakpoint-up(md){
        font-size: 2.5rem;
        line-height: 1.6;
        
        strong {
            font-size: 3rem;
        }
    }
}