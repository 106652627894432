.news-card {
    display: grid;
    grid-template-columns: 1fr;
    background-color: $white;
    overflow: hidden;
    box-shadow: 4px 4px 14px rgba(35, 28, 113, 0.1);
    
    @include media-breakpoint-up(md){
        grid-template-columns: 1fr 3fr;
    }
    @include media-breakpoint-up(xxl){
        grid-template-columns: 1fr 2fr;
    }

    &__thumb {
        height: 300px;
        width: 100%;
        overflow: hidden;
        
        @include media-breakpoint-up(sm) {
            height: 350px;
        }
        @include media-breakpoint-up(md) {
            height: 100%;
        }
        @include media-breakpoint-up(lg) {
            width: 180px;
        }

        @include media-breakpoint-up(xxl){
            height: 220px;
            width: 230px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            transition: transform .6s cubic-bezier(0.445, 0.645, 0.355, 1);
        }
    }

    &__body {
        padding: 1.5rem 1.25rem;
        line-height: 1.5;
        
        @include media-breakpoint-up(xxl){
            padding: 1.5rem 1.5rem 2rem;
        }

        h3 {
            font-size: 1.25rem;
            margin-bottom: 0.75rem;
        }
    }

    &__categories {
        display: flex;
        margin-bottom: 1rem;
        gap: 1rem;

        div {
            font-size: .75rem;
            font-weight: 500;
            text-transform: uppercase;
            padding: .4rem .9rem;
            color: var(--theme-color-blue);
            border: 1px solid var(--theme-color-blue);
        }
    }

    // = = = Big = =  

    &--big {
        grid-template-columns: 1fr;
    }

    &--big &__thumb {
        width: 100%;
        
        @include media-breakpoint-up(xxl) {
            height: 495px;
        }
    }

    &--big &__body {
        padding: 1.5rem 1.25rem 2rem;
    }

    // = = = Medium = = =

    &--medium {
        @extend .news-card--big;
        grid-template-rows: max-content;
        height: 100%;
    }

    &--medium &__thumb {
        width: 100%;
        height: 340px;
    }

    &--medium &__body {
        display: flex;
        flex-direction: column;
    }

    // = = = Wide = = =

    &--wide {
        display: flex;
        flex-direction: column;
        margin-bottom: 3rem;

        @include media-breakpoint-up(lg){
            flex-direction: row;
        }
    }

    &--wide &__thumb{
        width: 100%;
        height: 420px;
        
        @include media-breakpoint-up(lg){
            width: 50%;
            height: 500px;
        }
    }

    &--wide &__body{
        align-self: center;
        width: 100%;

        h3 {
            margin: 1.5rem 0;
        }

        @include media-breakpoint-up(lg){
            width: 50%;

            h3 {
                font-size: 2.25rem;
            }
        }
    }

    &:hover {
        color: inherit;
    }

    &:hover &__thumb {
        img {
            transform: scale(1.12) rotate(-4deg);
        }
    }
}