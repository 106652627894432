.hero {
    &__container {
        position: relative;
        display: flex;
        width: 100%;
        overflow: hidden;
        flex-direction: column-reverse;
        
        @include media-breakpoint-up(xl){
            flex-direction: row;
            height: 500px;
        }
        @include media-breakpoint-up(xxl){
            height: 600px;
        }

        &::before {
            content: '';
            position: absolute;
            top: 1.5rem;
            left: 0;
            width: 585px;
            height: 500px;
            background-image: url('./../img/svg/hero-before.svg');
            background-repeat: no-repeat;
            z-index: -3;
        }
    }

    &__text {
        padding: 2.5rem 1rem 1rem;
        text-align: center;
        width: 100%;
        
        @include media-breakpoint-up(sm){
            padding: 3rem 2rem 1rem;
        }

        @include media-breakpoint-up(md){
            text-align: left;
        }

        @include media-breakpoint-up(xl){
            width: 50%;
            padding: 6.5rem 0 0;
            margin-left: calc((100% - 1140px)/2);
        }

        @include media-breakpoint-up(xxl){
            margin-left: calc((100% - 1320px)/2);
        }
    }

    &__title {
        position: relative;
        z-index: 9;

        h1 {
            font-size: 2.25rem;
            
            @include media-breakpoint-up(md){
                font-size: 3.5rem;
                white-space: nowrap;
            }
            @include media-breakpoint-up(xxl){
                font-size: 4.5rem;
            }
        }
    }

    &__desc {
        margin: 2.5rem auto;
        max-width: clamp(360px, 95%, 600px);
        
        @include media-breakpoint-up(sm){
            margin: 2.5rem 0;
        }
        @include media-breakpoint-up(xl){
            max-width: clamp(360px, 75%, 600px);
        }
    }

    &__image {
        position: relative;
        top: 0;
        right: 0;
        width: 100%;
        height: 400px;
        z-index: -2;
        
        @include media-breakpoint-up(xl){
            position: absolute;
            width: 50%;
            height: 500px;
        }
        @include media-breakpoint-up(xxl){
            height: 600px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}