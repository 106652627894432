.btn {
    position: relative;
    font-weight: 500;
    letter-spacing: 1px;
    color: $white;
    text-transform: capitalize;
    padding: .75rem 1.5rem;
    min-width: 180px;
    border-radius: 0;
    overflow: hidden;
    z-index: 5;
    border: 1px solid var(--theme-color-primary);
    background-color: var(--theme-color-primary);
    transition: background-color .3s ease-in-out, color .3s ease-in-out;

    &::before {
        content: '';
        position: absolute;
        bottom: -2%;
        left: 0;
        width: 100%;
        height: 104%;
        background-color: $white;
        transform: translateY(102%);
        transition: transform .6s cubic-bezier(0.415, 0.610, 0.355, 1);
        z-index: -1;
    }

    &:focus {
        outline: none;
        box-shadow: none;
    }

    &:hover {
        color: var(--theme-color-black);

        &::before {
            transform: translateY(0);
        }
    }

    &--grey {
        border: 1px solid var(--theme-color-grey);
        background-color: var(--theme-color-grey);

        &::before {
            background-color: $white;
        }

        &:hover {
            color: var(--theme-color-grey);
        }
    }

    &--white {
        border: 1px solid $white;
        color: var(--theme-color-primary);
        background-color: $white;
        
        &::before {
            background-color: var(--theme-color-primary);
        }

        &:hover {
            color: $white;
        }
    }

    &--border {
        color: var(--theme-color-primary);
        background-color: transparent;
        border: 1px solid var(--theme-color-primary);

        &::before {
            background-color: var(--theme-color-primary);
        }

        &.btn--white {
            color: $white;
            background-color: transparent;
            border: 1px solid $white;

            &::before {
                background-color: $white;
            }

            &:hover {
                color: var(--theme-color-primary);
            }
        }

        &:hover {
            color: $white;
        }
    }
}

.link {
    font-weight: 500;
    color: var(--theme-color-black);
    border-bottom: 1px solid var(--theme-color-black);
    transition: border-color .25s ease-in-out;
    
    &:hover {
        border-bottom: 1px solid transparent;
    }

    &--blue {
        color: var(--theme-color-blue);
        border-bottom: 1px solid var(--theme-color-blue);
        
        &:hover {
             color: var(--theme-color-blue);    
        }
    }

    &--grey {
        color: var(--theme-color-grey);
        border-bottom: 1px solid var(--theme-color-grey);
        
        &:hover {
             color: var(--theme-color-grey);    
        }
    }
}