.header {
    position: relative;
    z-index: 99;

    &__toggler {
        float: right;
        padding: .25rem .5rem .5rem;
        background-color: transparent;
        border: none;

        .line {
            fill: none;
            stroke: black;
            stroke-width: 6;
            transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
                stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
        }
        .line1 {
            stroke-dasharray: 60 207;
            stroke-width: 6;
        }
        .line2 {
            stroke-dasharray: 60 60;
            stroke-width: 6;
        }
        .line3 {
            stroke-dasharray: 60 207;
            stroke-width: 6;
        }
        &.open .line1 {
            stroke-dasharray: 90 207;
            stroke-dashoffset: -134;
            stroke-width: 6;
        }
        &.open .line2 {
            stroke-dasharray: 1 60;
            stroke-dashoffset: -30;
            stroke-width: 6;
        }
        &.open .line3 {
            stroke-dasharray: 90 207;
            stroke-dashoffset: -134;
            stroke-width: 6;
        }
        
        @include media-breakpoint-up(sm){
            padding: 1rem;
        }
        @include media-breakpoint-up(xl){
            display: none;
        }
    }

    &__nav {
        display: none;
        width: 100%;
        background-color: $white;
        transition: height .3s cubic-bezier(.39,.575,.765,1);

        .__container {
            height: 100%;
        }

        &.show {
            display: block;
            height: calc(100vh - 100px);
        }

        @include media-breakpoint-down(xl) {
            position: fixed;
            padding: 2rem 0;
            overflow: scroll;
            left: 0;
            top: 100px;
        }

        @include media-breakpoint-up(xl) {
            display: block;
        }
    }
}