html {
	scroll-behavior: smooth;
}

body {
	position: relative;
	font-size: 1em;
	height: 100%;
	line-height: 1.65;
	font-family: $font-prime;
	color: var(--theme-color-grey);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.container-xl {
	@include media-breakpoint-between(sm,xl){
		padding-left: 3rem;
		padding-right: 3rem;
	}
}

.container-xxl {
	@include media-breakpoint-between(sm,xxl){
		padding-left: 3rem;
		padding-right: 3rem;
	}
}