.icon-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    @include media-breakpoint-up(lg){
        flex-direction: row;
        align-items: center;
    }

    &__icon {
        width: 100%;
        height: 110px;
        text-align: center;
        line-height: 110px;

        img {
            max-height: 90%;
        }

        @include media-breakpoint-up(sm){
            width: 110px;
        }

        @include media-breakpoint-up(xl){
            width: 180px;
            height: 180px;
            line-height: 180px;

             img {
                max-height: 120px;
            }
        }
    }

    &__body {
        padding-top: 1.25rem;

        @include media-breakpoint-up(lg){
            padding: 1rem;
            width: calc(100% - 110px);
        }
        
        @include media-breakpoint-up(xl){
            width: calc(100% - 180px);
        }
    }
}

.work-box {
    &__thumb {
        height: 340px;
        margin-bottom: 1.5rem;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}