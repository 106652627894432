.pagination {
    display: flex;
    gap: .5rem;
    list-style: none;
    padding: 0;
    margin: 1rem 0 1.5rem;

    .page-item {
        .page-link {
            color: var(--theme-color-black);
            border-radius: 0;
            border: 0;
            padding: .5rem .85rem;
            
            &.current {
                color: var(--theme-color-blue);
            }
        }
    }
}