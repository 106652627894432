// Bootstrap
a {
    transition: color .2s ease-in-out;
    
    &:hover {
        color: initial;
    }
}

.form-control {
    font-size: .875rem;
    min-height: 45px;
    border: none;
    border-radius: 0;
    background-color: #F1F1F2;

    &:focus {
        background-color: #F1F1F2;
        outline: none;
        box-shadow: none;
    }
}

textarea.form-control {
    padding: .75rem;
}

// Wordpress
.alignleft, img.alignleft {
	margin-right: 1.5em;
    float: left;
    max-width: 100%;
    height: auto;
}

.alignright, img.alignright {
	margin-left: 1.5em;
    float: right;
    max-width: 100%;
    height: auto;
}

.aligncenter, img.aligncenter {
	margin-right: auto;
	margin-left: auto;
	display: block;
    clear: both;
    max-width: 100%;
    height: auto;
}

.alignnone, img.alignnone {
    max-width: 100%;
    height: auto;
}

// Contact Form
.wpcf7-not-valid-tip {
    font-size: 12px !important;
    text-align: center;
}

.wpcf7-spinner {
    display: none !important;
}

.wpcf7-response-output {
    font-size: 14px;
    text-align: center;
    margin: 2em auto 1em !important;
    border-color: var(--theme-color-grey) !important;
}

.wpcf7-submit {
    &:hover {
        color: $white !important;
    }
}

.wpcf7-acceptance {
    .wpcf7-list-item {
        font-size: 14px;
        padding-top: .15rem;
        margin-left: 0 !important;

        input[type="checkbox"] + span {
            position: absolute;
            cursor: pointer;
            width: 16px;
            height: 16px;
            left: 0;
            border: 12px solid #7A8796;
            border-radius: 4px;
            top: 0;
            transition: all .3s ease-out;
            text-indent: 24px;
            white-space: nowrap;
            line-height: 0px;
            user-select: none;

            &:after {
                content: "";
                position: absolute;
                width: 0px;
                height: 7px;
                border-bottom: 2px solid #fff;
                border-left: 2px solid #fff;
                top: 22%;
                left: -2px;
                transform-origin: bottom left;
                transform: rotate(-45deg);
                opacity: 0;
                transition: all .3s ease-out;
            }
        }

        input[type="checkbox"]:checked + span {
            border: 12px solid #7A8796;

            &:after {
                opacity: 1;
                width: 13px;
            }
        }

        input {
            width: 16px;
            height: 16px;
            position: relative;
            top: 3px;
            margin-right: .5rem;
        }
    }
}
